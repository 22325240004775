@import '../_var.less';

@atom: ListAction;

.@{atom} {
    position: relative;
    display: inline-block;

    .@{atom}__list {
        @dim-list-width: 155px;

        transform: translateY(-15px);
        opacity: 0;
        transition: transform 0.15s ease-in-out, opacity 0.15s ease-in, z-index 0.16s 0.01ms;
        z-index: -1;
        transform-origin: top left;
        position: absolute;
        box-shadow: 0 0 5px rgba(0,0,0,0.4);
        background: @color-white;
        width: @dim-list-width;
        max-width: @dim-list-width;

        @dim-tip-size: 8px;

        &::before {
            content: "";
            position: absolute;
            top: -@dim-tip-size/2 + 1px;
            width: @dim-tip-size;
            height: @dim-tip-size;
            transform: rotate(45deg);
            background-color: @color-white;
            z-index: 1;
            box-shadow: -3px -3px 4px rgba(0, 0, 0, 0.1);
        }

        &.@{atom}__list--right {
            .mixin-list--right();
        }

        &.@{atom}__list--left {
            .mixin-list--left();
        }

        &.@{atom}__list--top {
            transform: translateY(calc(-100% - 80px));

            &::before {
                top: auto;
                bottom: -@dim-tip-size/2 + 1px;
                box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.1);
            }
        }

        .@{atom}__item {
            .@{atom}__itemLink {
                display: flex;
                flex-flow: row nowrap;
                align-items: center;
                text-decoration: none;
                padding: 6px 15px 6px 12px;

                .@{atom}__itemIcon {
                    color: fade(@color-primary, 40%);
                    margin-right: @dim-xs;
                    font-size: @font-md;
                }

                .@{atom}__itemTitle {
                    color: @color-main-text;
                    font-weight: bold;
                    font-family: @font-secondary;
                    font-size: @font-xs;
                    min-width: 100px;
                    text-align: left;
                }
            }

            &:first-child {
                padding-top: 8px;
            }

            &:last-child {
                padding-bottom: 8px;
            }
        }
    }
}