.mixin-input-style () {
    border: solid 1px #d0e7f6;
    padding: @dim-xs;
    border-radius: 2px;
    font-family: @font-primary;
    font-weight: 800;
    color: @color-dark-grey;
    margin-bottom: @dim-sm;
    font-size: 13px;

    &::placeholder {
        color: @color-placeholder;
        font-style: italic;
    }

    &[disabled] {
        .mixin-input-disabled-style();
    }

    &[readonly] {
        .mixin-input-disabled-style();
        .mixin-input-readonly-style();
    }
}

.mixin-input-disabled-style() {
    background: @color-disabled;
    color: @color-label;
    border-color: @color-grey !important;


    &::placeholder {
        color: @color-transparent;
    }
}

.mixin-input-readonly-style() {
    pointer-events: none;
    touch-action: none;
}

.mixin-input-search-style () {
    color: @color-dark-grey;
    border: none;
    border-radius: 15px;
    padding: 6px 8px;
    margin: 0 @dim-sm @dim-sm 0;
    background-color: @color-disabled;
    font-weight: 400;
}

.mixin-input-search-small-style () {
    .mixin-input-search-style();
    background: url("../../../public/ressources/img/expand_more.svg") no-repeat right;
    background-color: @color-disabled;
    -webkit-appearance: none;
    padding: 6.5px 8px 6.5px 13px;
    padding-left: 13px;
    padding-right: 35px;
    max-height: 30px;

    option {
        &[data-type="placeholder"] {
            color: @color-placeholder;
            display: block !important;
        }
    }

    &.Dropdown--white {
        background-color: @color-secondary;
        box-shadow: 0 0 10px rgba(0,0,0,.1);
    }

    &.Dropdown--square {
        box-shadow: none;
        border: solid 1px #c4dbea;
        border-radius: 3px;
    }
}

.mixin-dropdown-style () {
    text-transform: uppercase;
    border-radius: 10px;
    border: none;
    font-weight: 600;
    font-size: @font-xxs;
    background: url("../../../public/ressources/img/expand_more_translation.svg") no-repeat right center;
    background-size: 20px;
    -webkit-appearance: none;
    padding: 2px 16px 2px 7px;
    cursor: pointer;
}

.mixin-input-close-style() {
    content: "";
    background: url(../../../public/ressources/img/close.svg) @color-primary center center;
    background-size: 14px;

    @dim-size-close-btn: 16px;
    height: @dim-size-close-btn;
    max-height: @dim-size-close-btn;
    width: @dim-size-close-btn;
    border-radius: 50%;
    font-size: @font-md;
    display: flex;
    color: white;
    justify-content: center;
    align-items: center;
    background-size: 14px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto 5px;
}

.mixin-button-style () {
    font-family: @font-primary;
    font-weight: 600;
    border-radius: 3px;
    margin: 0 @dim-sm @dim-sm 0;
    padding: 10px 12px 9px 12px;
    color: @color-dark-grey;
    text-transform: uppercase;
    font-size: @font-sm - 0.1em;
    transition: all .1s ease-out;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
    border-width: 0;
    user-select: none;
    vertical-align: middle;
    height: fit-content;

    &:hover {
        background-color: @color-dark-grey;
        color: @color-white;
        border-color: @color-dark-grey;
    }
}

.mixin-list--right () {
    transform-origin: top right;
    left: -116px;
    right: inherit;
    &::before {
        right: @dim-sm;
        left: auto;
    }
}

.mixin-list--left () {
    transform-origin: top left;
    left: inherit;
    &::before {
        left: @dim-sm;
        right: auto;
    }
}